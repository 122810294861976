

















import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class PropertiesForRent extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  handleFilter(filter: ListingFilter) {
    this.listingFilter = filter;
    this.listingFilter.PageSize = 16;
  }
}
